import { Box, VStack } from "@chakra-ui/react"
import React, { FC } from "react"
import {
  SharedFirebaseContext,
  useFirebase,
} from "../../components/auth/FirebaseContext"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { BareNav, Nav } from "../../components/layout/Nav"
import { NoSsr } from "../../components/NoSsrContext"

const Boxed: FC = ({ children }) => {
  return (
    <Box
      overflow="hidden"
      borderWidth="10px"
      borderStyle="solid"
      borderColor="currentcolor"
      pb={4}
    >
      {children}
    </Box>
  )
}

const DebugNavInner = () => {
  const app = useFirebase()
  return (
    <VStack spacing={4} align="stretch" p={4}>
      <Boxed>
        <BareNav />
      </Boxed>
      <Boxed>
        <SharedFirebaseContext.Provider
          value={{
            app: null,
            user: null,
            isLoadingUser: false,
            init: () => {},
          }}
        >
          <Nav showBottomBorder />
        </SharedFirebaseContext.Provider>
      </Boxed>
      <Boxed>
        <SharedFirebaseContext.Provider
          value={{
            app,
            user: null,
            isLoadingUser: false,
            init: () => {},
          }}
        >
          <Nav showBottomBorder />
        </SharedFirebaseContext.Provider>
      </Boxed>
      <Boxed>
        <SharedFirebaseContext.Provider
          value={{
            app,
            // @ts-expect-error `user` doesn't have all the properties expected, but enough for the nav bar
            user: {
              email: "test@example.com",
            },
            isLoadingUser: false,
            init: () => {},
          }}
        >
          <Nav showBottomBorder />
        </SharedFirebaseContext.Provider>
      </Boxed>
    </VStack>
  )
}

export default function DebugNav() {
  return (
    <DebugLayout title="Nav" flush>
      <NoSsr>
        <DebugNavInner />
      </NoSsr>
    </DebugLayout>
  )
}
